import {initializeApp} from "firebase/app";
import {collection, getDocs, getDoc, orderBy, setDoc, doc, getFirestore, serverTimestamp, query, where, limit, startAfter, startAt} from "firebase/firestore";
import {useContext, useEffect, useState} from "react";
import {Context} from "../Store";
import {generateRandomString} from "../libs/utils";

export const getFirebaseConfig = () => {
    return  {
        // apiKey: "AIzaSyDrz3sZ2C7jh8KTOvhGbH7RVRlUAw69jEQ",
        // authDomain: "opecoin-3a4fd.firebaseapp.com",
        // projectId: "opecoin-3a4fd",
        // storageBucket: "opecoin-3a4fd.appspot.com",
        // messagingSenderId: "352129811500",
        // appId: "1:352129811500:web:60765187077ff41f9ffd89",
        // measurementId: "G-W70HJ30B4S"
        apiKey: "AIzaSyBYkEZxwaHvD_W9HhYti3DPaztMaDtApzQ",
        authDomain: "opencoinstake-io.firebaseapp.com",
        projectId: "opencoinstake-io",
        storageBucket: "opencoinstake-io.firebasestorage.app",
        messagingSenderId: "463039184118",
        appId: "1:463039184118:web:8129e77ff0fe723c8940d7",
        measurementId: "G-QZBTPZL4C7"
    };
}

export function useRefCode() {
    const [state, dispatch] = useContext(Context);
    const [app, setApp] = useState(null);
    const [db, setDb] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refCode, setRefCode] = useState(null);
    const initFirebase = () => {
        const _app = initializeApp(getFirebaseConfig());
        const _db = getFirestore(_app);
        setApp(_app);
        setDb(_db);
    }

    useEffect(() => {
        initFirebase();
    }, [])

    useEffect( () => {
        if(state.account && db) getDetail('Referals', state.account);
    }, [state, db]);

    const getDetail = async (collectionId, docId) => {
        setIsLoading(true);
        const colRef = collection(db, "Referals");
        let q = query(colRef, where("address", "==", docId));
        const docSnap = await getDocs(q);
        let exists = []
        docSnap.forEach((doc) => {
            exists.push(doc.data());
        })
        if (exists.length > 0) {
            setRefCode(exists[0]?.refCode)
            setIsLoading(false)
        } else {
            generateRefCode();
        }
    }

    const generateRefCode = async () => {
        let code = generateRandomString(6).toString().toUpperCase();
        let isValid = true;
        while (isValid) {
            const querySnapshot = await getDocs(collection(db, "Referals"));
            querySnapshot.forEach((doc) => {
                if (doc.data().refCode === code) {
                    isValid = true;
                    code = generateRandomString(6).toString().toUpperCase();
                } else {
                    isValid = false;
                }
            });
        }

        const data = {
            address: state.account,
            refCode: code,
        };
        const docRef = doc(db, "Referals", state.account);
        await setDoc(docRef, data);
        setRefCode(code);
        setIsLoading(false);
    }

    const putData = async (collectionId, docId, data) => {
        const docRef = doc(db, collectionId, docId);
        await setDoc(docRef, data);
    }

    return {app, db, refCode, isLoading, getDetail, putData}
}

export function useFirestore() {
    const [state, dispatch] = useContext(Context);
    const [app, setApp] = useState(null);
    const [db, setDb] = useState(null);
    const [dataLists, setDataLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initFirebase = () => {
        const _app = initializeApp(getFirebaseConfig());
        const _db = getFirestore(_app);
        setApp(_app);
        setDb(_db);
    }

    useEffect(() => {
        initFirebase();
    }, [])

    const putData = async (collectionId, docId, data) => {
        const _app = initializeApp(getFirebaseConfig());
        const _db = getFirestore(_app);
        setApp(_app);
        setDb(_db);
        const docRef = doc(_db, collectionId, docId);
        await setDoc(docRef, {
            ...data,
            createdAt: serverTimestamp(),
        });
    }

    const getData = async (
        collectionId,
        perPage=2,
        currentPage=0,
        orderColumn="createdAt",
        orderDir="desc",
        lastData=null,
        wherePayload=[]
    ) => {
        return new Promise(async(resolve, reject) => {
            setIsLoading(true);
            try {
                const _app = initializeApp(getFirebaseConfig());
                const _db = getFirestore(_app);
                setApp(_app);
                setDb(_db);
                const colRef = collection(_db, collectionId);
                let qTotal = query(colRef, orderBy(orderColumn, orderDir))
                wherePayload.forEach(clause => {
                    qTotal = query(qTotal, where(...clause));
                });
                const querySnapshotTotal = await getDocs(qTotal);
                const totalData = querySnapshotTotal.size;
                let q;
                if(lastData){
                    q = query(
                        colRef,
                        orderBy(orderColumn, orderDir),
                        startAfter(lastData),
                        limit(perPage*currentPage)
                    );
                    wherePayload.forEach(clause => {
                        q = query(q, where(...clause));
                    });
                } else {
                    q = query(
                        colRef,
                        orderBy(orderColumn, orderDir),
                        limit(perPage*currentPage)
                    );
                    wherePayload.forEach(clause => {
                        q = query(q, where(...clause));
                    });
                }


                const querySnapshot = await getDocs(q);
                let data = [];
                let newLastDoc = null;
                querySnapshot.forEach((doc) => {
                    if (!newLastDoc) {
                        newLastDoc = doc;
                    }
                    data.push(doc.data());
                });
                setIsLoading(false);
                setDataLists(data);
                resolve({
                    data: data,
                    total: totalData,
                    currentPage: currentPage,
                    perPage: perPage,
                    paginateLength: totalData / perPage,
                    lastData: newLastDoc,
                });
            } catch (e) {
                setIsLoading(false);
                reject(e);
            } finally {
                setIsLoading(false);
            }
        })

    }

    const getRow = async (collectionId, wherePayload) => {
        return new Promise(async(resolve, reject) => {
            setIsLoading(true);
            try {
                const _app = initializeApp(getFirebaseConfig());
                const _db = getFirestore(_app);
                setApp(_app);
                setDb(_db);
                const colRef = collection(_db, collectionId);
                let q = query(colRef)
                wherePayload.forEach(clause => {
                    q = query(q, where(...clause));
                });
                const querySnapshot = await getDocs(q);
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                setIsLoading(false);
                setDataLists(data);
                resolve(data);
            } catch (e) {
                setIsLoading(false);
                reject(e);
            } finally {
                setIsLoading(false);
            }
        })
    }

    return {app, db, isLoading, dataLists, putData, getData, getRow}
}