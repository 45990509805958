import React, { Fragment, useContext, useEffect, useState } from "react";
import { Header, Footer } from "../components/opencoin-ui";
import { RiArrowDownCircleLine, RiArrowDownLine, RiArrowLeftLine, RiArrowRightLine, RiCheckLine } from "react-icons/ri";
import { Context } from "../Store";
import AvatarWallet from "../components/AvatarWallet";
import { amountFormat, getSimpleAddress, truncate } from "../libs/WebooLib";
import { useFirestore } from "../hooks/useFirestore";
import { getAppByChainId } from "../libs/Env";
import { parseFromWei } from "../libs/utils";
import { ClipLoader } from "react-spinners";
import { data } from "autoprefixer";


export default function Information() {
    const [state, dispatch] = useContext(Context);
    const { app, db, isLoading, putData, getData } = useFirestore();
    const [dataLists, setDataLists] = useState([]);
    const [paginateLength, setPaginateLength] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastData, setLastData] = useState(null);

    const getListData = () => {
        getData(
            "Transactions",
            10,
            currentPage,
            "createdAt",
            "desc",
            dataLists.length > 0 ? dataLists[dataLists.length - 1] : null
        ).then((res) => {
            let tmp = [];
            res?.data.map((v) => {
                const exits = dataLists.find((x) => x?.hash === v?.hash)
                if (!exits) {
                    tmp.push(v)
                }

            })
            setDataLists([
                ...dataLists,
                ...tmp
            ])
            setLastData(res?.lastData)
            setPaginateLength(res?.total)
        });
    }

    useEffect(() => {
        getListData()
    }, [currentPage])

    return (
        <>
            <Header />
            <section
                className="relative px-4 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
                <div className="w-full p-6 mb-6 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
                    <div className="flex justify-between w-full">
                        <div>
                            <h1 className="mb-4 font-semibold">Wallet</h1>
                            <div className="flex items-center gap-3">
                                <AvatarWallet address={state.account ? state.account : "0xopencoin"} diameter={25} />
                                <div className="hidden md:block lg:block xl:block">
                                    {state.account ? state.account : "0x"}
                                </div>
                                <div className="block md:hidden lg:hidden xl:hidden">
                                    {state.account ? truncate(state.account, 4, '...', 50) : "0x"}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h1 className="mb-4 font-semibold text-end">Explorer</h1>

                            <a href={`https://bscscan.com/address/${state.account ? state.account : "0xopencoin"}`} className="px-6 py-3 text-white rounded-lg bg-grayc-900">BSCSCAN</a>
                        </div>
                    </div>
                </div>

                <div className="w-full p-6 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
                    <h1 className="font-semibold">Transaction History</h1>

                    <section className="container mx-auto mt-4">
                        <div className="flex flex-col">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div
                                        className="overflow-hidden border border-gray-400 dark:border-gray-700 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                            <thead className="bg-gray-100 dark:bg-gray-800">
                                                <tr>
                                                    <th scope="col"
                                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-600 dark:text-gray-400">
                                                        <div className="flex items-center gap-x-3">
                                                            {/*<input type="checkbox" className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700" />*/}
                                                            <button className="flex items-center gap-x-2">
                                                                <span>ID</span>

                                                                <svg className="h-3" viewBox="0 0 10 11" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                                                        fill="currentColor" stroke="currentColor"
                                                                        stroke-width="0.1" />
                                                                    <path
                                                                        d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                                                        fill="currentColor" stroke="currentColor"
                                                                        stroke-width="0.1" />
                                                                    <path
                                                                        d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                                                        fill="currentColor" stroke="currentColor"
                                                                        stroke-width="0.3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </th>

                                                    <th scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-600 dark:text-gray-400">
                                                        Tx Hash
                                                    </th>

                                                    <th scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-600 dark:text-gray-400">
                                                        Type
                                                    </th>

                                                    <th scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-end rtl:text-right text-gray-600 dark:text-gray-400">
                                                        Amount
                                                    </th>

                                                    <th scope="col"
                                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-600 dark:text-gray-400">
                                                        Check
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                {dataLists.map((item, index) => {
                                                    return (
                                                        <tr key={"list-" + index}>
                                                            <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                                                                <div className="inline-flex items-center gap-x-3">
                                                                    {/*<input type="checkbox"*/}
                                                                    {/*       className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>*/}

                                                                    <span>#{index + 1}</span>
                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-300 whitespace-nowrap">
                                                                {getSimpleAddress(item?.hash)}
                                                            </td>
                                                            <td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-300 whitespace-nowrap">
                                                                {item?.type === 'Approve' ? (
                                                                    <div
                                                                        className="inline-flex items-center px-3 py-1 text-green-500 rounded-full gap-x-2 bg-green-200/60 dark:bg-gray-800">
                                                                        <RiCheckLine />
                                                                        <h2 className="text-sm font-normal">{item?.type}</h2>
                                                                    </div>
                                                                ) : item?.type === 'ClaimDividend' ? (
                                                                    <div
                                                                        className="inline-flex items-center px-3 py-1 text-orange-500 rounded-full gap-x-2 bg-orange-200/60 dark:bg-gray-800">
                                                                        <RiCheckLine />
                                                                        <h2 className="text-sm font-normal">{item?.type}</h2>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="inline-flex items-center px-3 py-1 text-blue-500 rounded-full gap-x-2 bg-blue-200/60 dark:bg-gray-800">
                                                                        <RiCheckLine />
                                                                        <h2 className="text-sm font-normal">{item?.type}</h2>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="px-4 py-4 text-sm text-gray-900 text-end dark:text-gray-300 whitespace-nowrap">
                                                                Est: {amountFormat(parseFromWei(item?.amount))} {item?.amountSymbol}
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex items-center self-end w-full flex-end text-end">
                                                                    <a href={`https://bscscan.com/tx/${item?.hash}`}
                                                                        target={"_blank"}
                                                                        rel="noreferrer"
                                                                        className="px-4 py-2 rounded-md bg-yellowc-100 text-yellowc-700">
                                                                        BSCSCAN
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                {(dataLists.length === 0 && !isLoading) && (
                                                    <tr>
                                                        <td colSpan={5} className={"text-center p-2"}>
                                                            No Data
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                        {(dataLists.length === 0 && isLoading) && (
                                            <div
                                                className={"text-center flex items-center w-full bg-white justify-center"}>
                                                <ClipLoader color={"#f59e0b"} loading={isLoading} size={20} />
                                            </div>
                                        )}
                                        {(dataLists.length < paginateLength) && (
                                            <div
                                                className={"text-center flex items-center w-full bg-white justify-center"}>
                                                <a href="/#"
                                                    className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCurrentPage(currentPage + 1)
                                                    }}
                                                >
                                                    <ClipLoader color={"#f59e0b"} loading={isLoading} size={20} />
                                                    {!isLoading && <RiArrowDownLine />}

                                                    <span>
                                                        Load More
                                                    </span>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <Footer />
        </>
    )
}