import BigNumber from "bignumber.js"

export const parseFromWei = (amount, decimal = 18, precision = 8) => {
  let amountBigNumber = new BigNumber(amount)
  const divison = new BigNumber(10 ** decimal);
  if(amountBigNumber.lt(1)) return amountBigNumber.dividedBy(divison).toFixed(precision)
  return amountBigNumber.dividedBy(divison).toFixed(4)
}

export const isGreaterThan = (one, two) => {
  const _one = new BigNumber(one);
  const _two = new BigNumber(two);
  return _two.isGreaterThan(_one);
}

export const formatNumericWithComma = (number) => {
  const defaultNumber = number
  return defaultNumber.toLocaleString();
}

export const generateRandomString = (length = 6) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const copyToClipboard = (toast,text) => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toast.success("Copied to clipboard")
}