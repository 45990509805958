import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./Store";
import Homepage from "./pages/Homepage";
import Home from "./pages/Home";
import Referral from "./pages/Referral";
import Information from "./pages/Information";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";

export default function Navigation() {

    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Homepage />} />
                    <Route path={"/staking"} element={<Home />} />
                    <Route path={"/referral"} element={<Referral />} />
                    <Route path={"/information"} element={<Information />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}