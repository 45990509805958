import { useEffect, useState } from "react";
import { RiFileCopy2Fill, RiFileCopy2Line } from "react-icons/ri";

export function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        let timeout;
        if (isCopied) {
            timeout = setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isCopied]);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <button onClick={handleCopyClick} aria-label="copy">
            <span>{isCopied ? (<div className="flex items-center gap-1"><RiFileCopy2Fill /> <span>Copied</span></div>) : (<RiFileCopy2Line />)}</span>
        </button>
    );
}