import {useState, useEffect, useContext} from 'react'
import {getAppByChainId, getEnv} from "../libs/Env";
import {Context} from "../Store";
import BigNumber from "bignumber.js";
import {toast} from 'react-toastify';
import {useFirestore, useRefCode} from "./useFirestore";

export const useCheckIsNeedApprove = () => {
    const [state, dispatch] = useContext(Context);
    const [amountAllowance, setAmountAllowance] = useState(null)
    const [isLoadingCheck, setIsLoadingCheck] = useState(false)
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [txHashApprove, setTxHashApprove] = useState(null)
    const {app, db, isLoading, putData} = useFirestore();

    const fetchCheck = async (tokenAddress, to) => {
        setIsLoadingCheck(true)
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, state.web3.utils.toChecksumAddress(tokenAddress));
        const allowance = new BigNumber(await token.methods.allowance(state.account, to).call());
        let balance = (new BigNumber(await token.methods.balanceOf(state.account).call()));
        if (balance.isGreaterThanOrEqualTo(allowance)) {
            setIsNeedApprove(true);
        } else {
            setIsNeedApprove(false);
        }
        setIsLoadingCheck(false)
    }

    const fecthApprove = async (tokenAddress, to) => {
        setIsLoadingCheck(true)
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, state.web3.utils.toChecksumAddress(tokenAddress));
        let balance = (new BigNumber(await token.methods.balanceOf(state.account).call())).multipliedBy(2);
        // console.log((balance.toFixed(0)))
        await token.methods.approve(to, state.web3.utils.toWei(balance.toFixed(0).toString())).send({
            from: state.account
        }).on("transactionHash", async (hash) => {
            await putData('Transactions', hash, {
                account: state.account,
                chainId: state.chainId,
                amount: balance.toFixed(0).toString(),
                amountSymbol: await token.methods.symbol().call(),
                hash: hash,
                type: 'Approve',
                data: {
                    tokenAddress: tokenAddress,
                    to: to,
                    amount: balance.toFixed(0).toString(),
                }
            })
            // setTxHashApprove(hash)
            toast.success('Transaction Submitted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .on("receipt", (hash) => {
                setTxHashApprove(hash?.hash);
                setIsLoadingCheck(false);
                setIsNeedApprove(false)
                toast.success('Transaction Receipt', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .on("error", (error) => {
                setIsLoadingCheck(false);
                toast.error('Transaction Canceled', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    return [{amountAllowance, isNeedApprove, isLoadingCheck, txHashApprove}, fetchCheck, fecthApprove];
}

export const useTransferToken = () => {
    const [state, dispatch] = useContext(Context);
    const [isLoadingTransfer, setIsLoading] = useState(false)
    const [txHashTransfer, setTxHash] = useState(null)

    const fetchTransfer = async (tokenAddress, to, amount) => {
        setIsLoading(true)
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, state.web3.utils.toChecksumAddress(tokenAddress));
        // console.log((balance.toFixed(0)))
        await token.methods.transfer(to, state.web3.utils.toWei(amount)).send({
            from: state.account
        }).on("transactionHash", (hash) => {
            // setTxHashApprove(hash)
            toast.success('Transaction Submitted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .on("receipt", (hash) => {
                setTxHash(hash);
                setIsLoading(false);
                toast.success('Transaction Receipt, TX Hash =' + hash, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .on("error", (error) => {
                setIsLoading(false);
                toast.error('Transaction Canceled', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    return [{isLoadingTransfer, txHashTransfer}, fetchTransfer];
}

export const useGetERC20Detail = () => {
    const [state, dispatch] = useContext(Context);
    const [isLoadingTokenInfo, setIsLoading] = useState(false);
    const [tokenName, setTokenName] = useState(null);
    const [tokenSymbol, setTokenSymbol] = useState(null);
    const [tokenDecimal, setTokenDecimal] = useState(null);
    const [tokenSupply, setTokenSupply] = useState(null);
    const [tokenBalance, setTokenbalance] = useState(0);

    const fetchTokenInfo = async (address) => {
        if (state.account === null) return;
        setIsLoading(true);
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).ERC20_ABI,
            state.web3.utils.toChecksumAddress(address)
        );
        setTokenName(await token.methods.name().call());
        setTokenSymbol(await token.methods.symbol().call())
        setTokenSupply(await token.methods.totalSupply().call())

        let dec = await token.methods.decimals().call();
        setTokenDecimal(dec)

        let amount = new BigNumber(await token.methods.balanceOf(state.account).call())
        amount = amount.dividedBy((new BigNumber(10).pow(dec)))
        setTokenbalance(amount.toFixed(8).toString());
        setIsLoading(false);
    }

    return [{tokenName, tokenSymbol, tokenDecimal, tokenSupply, tokenBalance, isLoadingTokenInfo}, fetchTokenInfo];
}

export const useDynamicCallFunction = () => {
    const [state] = useContext(Context);
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dynamicCall = async (address, abi, methodName, params = []) => {
        return new Promise(async (resolve, reject) => {
            if (!state.web3) return;
            setIsLoading(true);
            try {
                const contract = new state.web3.eth.Contract(
                    abi,
                    state.web3.utils.toChecksumAddress(address)
                );

                const method = contract.methods[methodName];
                if (!method) {
                    throw new Error(`Method ${methodName} not found`);
                }

                let response = await method(...params).call();
                if (methodName === 'balanceOf') {
                    const decimals = await contract.methods.decimals().call();
                    response = new BigNumber(response).dividedBy(new BigNumber(10).pow(decimals)).toFixed(8).toString();
                }

                setResult(response);
                resolve(response);
            } catch (error) {
                setResult(null);
                reject(error);
            } finally {
                setIsLoading(false);

            }

        });
    };

    return [{result, isLoading}, dynamicCall];
};

export const useDynamicSendFunction = () => {
    const [state] = useContext(Context);
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const  {app, db, isLoading:isLoadingFirestore, putData} = useFirestore();

    const dynamicSend = async (address, abi, methodName, params = [], amountInWei=0, amountSymbol="-") => {
        return new Promise(async (resolve, reject) => {
            if (!state.web3) return;
            setIsLoading(true);
            try {
                const contract = new state.web3.eth.Contract(
                    abi,
                    state.web3.utils.toChecksumAddress(address)
                );

                const method = contract.methods[methodName];
                if (!method) {
                    throw new Error(`Method ${methodName} not found`);
                }

                let txHash = null;
                const response = await method(...params).send({
                    from: state.account,
                }).on("transactionHash", async (hash) => {
                    txHash = hash;
                    await putData('Transactions', hash, {
                        account: state.account,
                        chainId: state.chainId,
                        amount: amountInWei.toString(),
                        amountSymbol: amountSymbol,
                        hash: hash,
                        type: methodName.toString().charAt(0).toUpperCase() + methodName.toString().slice(1),
                        data: {
                            to: address,
                            ...params
                        }
                    })
                    // setTxHashApprove(hash)
                    toast.success('Transaction Submitted', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

                setResult(response);
                resolve({
                    txHash: txHash,
                    response: response
                });
            } catch (error) {
                setResult(null);
                reject(error);
            } finally {
                setIsLoading(false);
            }
        });
    };

    return [{result, isLoading}, dynamicSend];
}

export const useGetListToken = () => {
    const [state, dispatch] = useContext(Context);
    const [isLoadingListToken, setisLoading] = useState(false);
    const [listToken, setListToken] = useState([]);
    const fetchListToken = async (addresses = []) => {
        let tmpList = [];
        for (let i = 0; i < addresses.length; i++) {
            const token = await new state.web3.eth.Contract(
                getAppByChainId(state.chainId).ERC20_ABI,
                state.web3.utils.toChecksumAddress(addresses[i])
            );
            let dec = await token.methods.decimals().call();
            let tmp = {
                address: addresses[i],
                name: await token.methods.name().call(),
                symbol: await token.methods.symbol().call(),
                supply: await token.methods.totalSupply().call(),
                decimal: dec,
            }
            tmpList.push(tmp)
        }
        setListToken(tmpList)
    }
    return [{listToken, isLoadingListToken}, fetchListToken, setListToken]
}

export const useCheckTokenPrice = () => {
    const [state] = useContext(Context);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [tokenPrice, setTokenPrice] = useState(null);

    const getTokenPrice = async (tokenAddress, baseTokenAddress = null) => {
        setIsLoadingPrice(true);
        try {
            // Default to BUSD if no base token is provided
            const BASE_TOKEN = baseTokenAddress || getAppByChainId(state.chainId).USDT_ADDRESS;
            const PANCAKE_ROUTER = getAppByChainId(state.chainId).ROUTER_ADDRESS;

            const router = new state.web3.eth.Contract(
                getAppByChainId(state.chainId).PANCAKE_ROUTER_ABI,
                PANCAKE_ROUTER
            );

            // Get token contracts to fetch decimals
            const tokenContract = new state.web3.eth.Contract(
                getAppByChainId(state.chainId).ERC20_ABI,
                state.web3.utils.toChecksumAddress(tokenAddress)
            );

            const baseTokenContract = new state.web3.eth.Contract(
                getAppByChainId(state.chainId).ERC20_ABI,
                state.web3.utils.toChecksumAddress(BASE_TOKEN)
            );

            // Get decimals for both tokens
            const tokenDecimals = await tokenContract.methods.decimals().call();
            const baseTokenDecimals = await baseTokenContract.methods.decimals().call();

            // Amount of token to get price for (1 token)
            const amountIn = new BigNumber(10).pow(tokenDecimals).toString();

            // Get amounts out
            const amountsOut = await router.methods.getAmountsOut(
                amountIn,
                [
                    state.web3.utils.toChecksumAddress(tokenAddress),
                    state.web3.utils.toChecksumAddress(BASE_TOKEN)
                ]
            ).call();

            // Calculate price
            const price = new BigNumber(amountsOut[1])
                .dividedBy(new BigNumber(10).pow(baseTokenDecimals))
                .toFixed(8);

            setTokenPrice(price);
            setIsLoadingPrice(false);
            return price;

        } catch (error) {
            console.error("Error fetching token price:", error);
            setTokenPrice(null);
            setIsLoadingPrice(false);
            throw error;
        }
    };

    const getPriceInBNB = async () => {
        return getTokenPrice(
            getAppByChainId(state.chainId).TOKEN_ADDRESS,
            getAppByChainId(state.chainId).WETH_ADDRESS
        );
    };

    const getPriceInBUSD = async () => {
        return getTokenPrice(
            getAppByChainId(state.chainId).TOKEN_ADDRESS,
            getAppByChainId(state.chainId).USDT_ADDRESS
        );
    };

    return [{
        tokenPrice,
        isLoadingPrice
    }, {
        getTokenPrice,
        getPriceInBNB,
        getPriceInBUSD
    }];
};

/** Custom web3 hook */

export const useGetTotalDonation = (scamAddress) => {
    const [state, dispatch] = useContext(Context);
    const [isLoadingTotalDonation, setIsLoading] = useState(false);
    const [amountDonation, setAmountDonation] = useState(null)
    const [totalContributor, setTotalContributor] = useState(0)

    useEffect(() => {
        if (state && state.storeHash) {
            fetchTotalDonation();
        }
    }, [state])

    const fetchTotalDonation = async (tokenAddress, to) => {
        setIsLoading(true);
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).ERC20_ABI,
            getAppByChainId(state.chainId).TOKEN_ADDRESS
        );
        const decimal = await token.methods.decimals().call();
        // alert(decimal)
        const bountyScammerContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).BOUNTYSCAMMER_ABI,
            getAppByChainId(state.chainId).BOUNTYSCAMMER_ADDRESS
        );
        let amount = new BigNumber(await bountyScammerContract.methods.leftAmountProject(state.web3.utils.toChecksumAddress(scamAddress)).call());
        amount = amount.dividedBy((new BigNumber(10).pow(decimal)))
        setAmountDonation(amount.toFixed(8).toString())
        setTotalContributor(await bountyScammerContract.methods.totalContributors(state.web3.utils.toChecksumAddress(scamAddress)).call())
        setIsLoading(false);
    }

    return [{amountDonation, totalContributor, isLoadingTotalDonation}, fetchTotalDonation];
}